// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

const HeroButton = ({ text, svgIcon, anchorLink }) => {
  const { btn, btnBig, btnText, iconSvg, link } = Style;

  return (
    <a target="_blank" href={anchorLink} className={link}>
      <button className={[btn, btnBig].join(' ')}>
        <span className={btnText}>{text}</span>
        <i className={iconSvg} dangerouslySetInnerHTML={{ __html: svgIcon }} />
      </button>
    </a>
  );
};

export default HeroButton;
