// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import ReportsList from '../ReportsList';
import Img from '@components/ImageWrapper';
import { translate as t } from '../../utils/translate';

const TEXTS = {
  REPORTS_FIELD: t('reports'),
  REFERENCES: t('forTheYears'),
};

const SectionReports = ({ backgroundImage, reports }) => {
  return (
    <section className={Style.sectionReports}>
      <Img
        src={backgroundImage}
        style={{ position: 'absolute', overflow: 'inherit' }}
        className={Style.backgroundImage}
      />
      <div className={Style.backgroundOverlay} />
      <div className={`${Style.textContainer} container`}>
        <h2 className={Style.title}>{TEXTS.REPORTS_FIELD}</h2>
        <p className={Style.subtitle}>{TEXTS.REFERENCES}:</p>
      </div>
      <ReportsList reports={reports} />
    </section>
  );
};

export default SectionReports;
