import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import HeroInvestors from '../components/HeroInvestors';
import SectionReports from '../components/SectionReports';
import MemorableSection from '../components/MemorableSection';
import SectionArticles from '../components/SectionArticles';
import polylangTranslationPathHelper from '@utils/polylangTranslationPathHelper';

const Investidores = ({ data, location }) => {
  const {
    wordpressPage: page,
    wordpressAcfPages: {
      acf: {
        investor_hero: hero,
        investor_reports_section: reports,
        investor_memorable_section: memorable,
        investor_articles: articles,
      },
    },
  } = data;

  return (
    <Layout
      dispatchedLang={location.state && location.state.dispatchLang}
      pageLang={page.polylang_current_lang}
      translations={page.polylang_translations}
      useTranslationsPath={true}
    >
      <SEO {...page} pageLang={page.polylang_current_lang} />
      <HeroInvestors {...hero} />
      <SectionReports {...reports} />
      <MemorableSection
        {...memorable}
        backgroundImage={memorable.backgroundImage}
      />
      <SectionArticles posts={articles} negativeOffset={true} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      polylang_current_lang
      polylang_translations {
        path
        polylang_current_lang
      }
      yoast_head
    }
    wordpressAcfPages(wordpress_id: { eq: $wordpress_id }) {
      acf {
        investor_hero {
          title
          text
          buttonText
          svgIcon
          srcImg {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }

          anchor {
            url {
              localFile {
                publicURL
              }
            }
          }
        }
        investor_articles {
          excerpt
          title
          link
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        investor_memorable_section {
          backgroundImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          text
        }
        investor_reports_section {
          backgroundImage {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          reports {
            description
            title
            year

            link {
              url {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Investidores;
