// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import Img from '@components/ImageWrapper';

const MemorableSection = ({ backgroundImage, text }) => {
  return (
    <section className={Style.memorableSection}>
      <Img
        src={backgroundImage}
        style={{ position: 'absolute', overflow: 'inherit' }}
        className={Style.backgroundImage}
      />
      <div className="container">
        <p className={Style.text}>{text}</p>
      </div>
    </section>
  );
};

export default MemorableSection;
