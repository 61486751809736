// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import HeroButton from '../HeroButton';
import ScrollButton from '../ScrollButton';
import Img from '@components/ImageWrapper';
import { translate as t } from '../../utils/translate';

const HeroInvestors = ({
  title,
  text,
  srcImg,
  buttonText,
  svgIcon,
  anchor,
}) => {
  const TEXTS = {
    LEARN_MORE: t('learnMore'),
  };

  return (
    <section className={Style.heroInvestors}>
      <Img
        src={srcImg}
        style={{ position: 'absolute', overflow: 'inherit' }}
        className={[Style.heroImage, Style.heroBackgroundLayer].join(' ')}
      />
      <div
        className={[Style.leftGradient, Style.heroBackgroundLayer].join(' ')}
      />
      <div
        className={[Style.bottomGradient, Style.heroBackgroundLayer].join(' ')}
      />
      <div className={[Style.heroContent, 'container'].join(' ')}>
        <p className={Style.title}>{title}</p>
        <p className={Style.text}>{text}</p>
        <HeroButton
          text={buttonText}
          svgIcon={svgIcon}
          anchorLink={anchor.url.localFile.publicURL}
        />
      </div>
      <div className={Style.scrollButtonContainer}>
        <ScrollButton text={TEXTS.LEARN_MORE} refID="heroAboutUsAnchor" />
      </div>
    </section>
  );
};

export default HeroInvestors;
