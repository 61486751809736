// TODO: REFACTORING
import React, { useState } from 'react';
import Style from './style.module.css';
import Button from '../Button';
import { translate as t } from '../../utils/translate';

const ReportsList = ({ reports }) => {
  const [selected, selectReport] = useState(0);

  const reportsList = reports.map((report, index) => {
    const TEXTS = {
      VIEW_PDF: t('viewPdf'),
    };
    return (
      <div
        key={index}
        onClick={() => selectReport(index)}
        className={`${Style.reportContainer} ${Style['report' + index]} ${
          index <= selected ? Style.selected : ''
        }`}
      >
        <div className={Style.report}>
          <div className={Style.yearContainer}>
            <div className={Style.year}>{report.year}</div>
          </div>
          <div className={Style.content}>
            <p className={Style.title}>{report.title}</p>
            <p className={Style.description}>{report.description}</p>
            <a
              className={Style.link}
              target="_blank"
              href={report.link.url.localFile.publicURL}
            >
              <Button
                text={TEXTS.VIEW_PDF}
                iconSide="left"
                style={{
                  color: '#91939B',
                  boxShadow: '10px 4px 40px #7796BB52',
                }}
              />
            </a>
          </div>
        </div>
      </div>
    );
  });

  return <div className={Style.reportsList}>{reportsList}</div>;
};

export default ReportsList;
